// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-template-js": () => import("/Users/NickLoeper/Desktop/Side Shit/Full Scope Web/full-scope-web/src/templates/ProjectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-pages-about-js": () => import("/Users/NickLoeper/Desktop/Side Shit/Full Scope Web/full-scope-web/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/Users/NickLoeper/Desktop/Side Shit/Full Scope Web/full-scope-web/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-how-it-works-js": () => import("/Users/NickLoeper/Desktop/Side Shit/Full Scope Web/full-scope-web/src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("/Users/NickLoeper/Desktop/Side Shit/Full Scope Web/full-scope-web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/Users/NickLoeper/Desktop/Side Shit/Full Scope Web/full-scope-web/.cache/data.json")

